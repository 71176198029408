import React from "react";
import {Link} from "react-router-dom";
import {Col, Row} from "antd";
import "./RecommendListItem.less";
import {PositionDef} from "../../config/PositionDef";


/**
 * 推荐职位
 * @author shawn
 */
export default class RecommendListItem extends React.Component<any, any> {

    constructor(props:any) {
        super(props);
        this.state = {
            def: this.props.def
        }
    }

    render(): React.ReactNode {
        let def:PositionDef = this.state.def;

        let nowDate = new Date();
        let year = nowDate.getFullYear();
        let month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
        let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
        let dateStr = year + "-" + month + "-" + day;

        return (
            <div id={"RecommendListItem"}>
                <Link to={`/position/${def.id}`}>
                    <Row>
                        <Col span={15}>
                            <span className={"RecommendListItem-text"}>{def.title}</span>
                        </Col>

                        <Col span={9}>
                            <span className={"RecommendListItem-text"}> {dateStr}</span>
                        </Col>
                    </Row>
                </Link>
            </div>
        );
    }
}