import React from "react";
import "./JobCategory.less"
import {Link} from "react-router-dom";
import {Col, Row} from "antd";

/*类型定义*/
const TYPE_LIST = [
    'develop',
    'art',
    'design',
    'market'
];

const IMG_URL=[10, 13, 11, 12];

/**
 * 职位分类
 * @author shawn
 */
export default class JobCategory extends React.Component<any, any> {

    constructor(props:any) {
        super(props);

        this.state = {
            type: this.props.type
        };
    }

    componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
        let type = nextProps.type;
        this.setState({type});
    }

    /*兼容IE,直接用JS*/
    onMouseEnter = (e:any, index:number) => {
        // console.log("==onMouseEnter==");
        // console.log(e);
        let img = require(`../../assets/images/jobs/${IMG_URL[index]}-1.png`);
        let target:any = e.currentTarget;
        target.setAttribute("src", img);
    };

    onMouseOut = (e:any, index:number) => {
        // console.log("==onMouseOut==");
        // console.log(e);
        let img = require(`../../assets/images/jobs/${IMG_URL[index]}.png`);
        let target:any = e.currentTarget;
        target.setAttribute("src", img);
    };

    render(): React.ReactNode {

        let elementList = [];
        for (let i = 0, len = TYPE_LIST.length; i < len; i++) {
            let img = require(`../../assets/images/jobs/${IMG_URL[i]}.png`);
            let styleClass = `JobCategory-item JobCategory-item-${i+1}`;
            if (this.state.type === TYPE_LIST[i]) {
                styleClass += ` JobCategory-item-${i+1}-hover`;
            }
            elementList.push(
                <Col span={6} key={`${i+1}`}>
                    <Link to={`/job/${TYPE_LIST[i]}`}>
                        <div style={{textAlign: "center"}}>
                            <img src={img} alt={""} className={styleClass} onMouseEnter={e => this.onMouseEnter(e, i)}
                                 onMouseOut={e => this.onMouseOut(e, i)}
                            ></img>
                        </div>
                    </Link>
                </Col>
            )
        }
        // {/*<Row justify="space-around" gutter={[{ xs: 8, sm: 16, md: 24, lg: 24 },{ xs: 8, sm: 16, md: 24, lg: 24 }]}>*/}
        
        return (
            <div style={{marginTop: "0.5rem", minWidth: "6rem"}} >
                <Row justify="center" gutter={[{ xs: 24, sm: 24, md: 24, lg: 32 },{ xs: 24, sm: 24, md: 24, lg: 32 }]} align={"bottom"}>
                    {elementList}
                </Row>
            </div>
        );
    }
}