import React from "react";
import "./Home.css";
import CompanyProfile from "../company-profile/CompanyProfile";
import HomeGames from "../home-games/HomeGames";
import HomeBanner from "./HomeBanner";
import HomeCarousel from "../carousel/HomeCarousel";

/**
 * 主页组件
 * @author shawn
 */
export default class Home extends React.Component {

    render() {
        return (
            <div id="Home">
                {/*首页顶部banner轮播图*/}
                <HomeCarousel/>
                {/*中间跳转3大图*/}
                <HomeBanner/>
                {/*公司简介*/}
                <CompanyProfile/>
                {/*底部下面游戏卡片循环轮播*/}
                <HomeGames/>
            </div>
        );
    }
}