import React from "react";
import "./JobTypeView.css";
import {positionConfig} from "../../config/xls";
import {Col, List, Row} from "antd";
import SearchBox from "./SearchBox";
import JobCategory from "./JobCategory";
import JobListItem from "./JobListItem";

/**
 * 搜索结果
 * @author shawn
 */
export default class SearchResult extends React.Component<any, any> {

    getKeyword = (search:string):string => {
        if (search.indexOf("?") !== -1) {
            let str = search.substring(1);
            let list = str.split("=");
            return decodeURIComponent(list[1] || "");  //防止中文
        }
        return "";
    };

    constructor(props:any) {
        super(props);

        let keyword =  this.props.location.state.keyword;   //通过单页面
        // let keyword = this.getKeyword(this.props.location.search);
        // console.log("关键词=" + keyword);

        this.state = {
            type: "search",    //岗位类别
            data: this.getPositionList(keyword),    //所有职位
        };
    }

    getPositionList(keyword:string) {
        return positionConfig.filter(def => {
            return def.title.indexOf(keyword) !== -1 ||
                def.degree.indexOf(keyword) !== -1 ||
                def.position.indexOf(keyword) !== -1 ||
                def.workLimit.indexOf(keyword) !== -1 ||
                def.tips.indexOf(keyword) !== -1
        });
    }

    /*点击类别切换*/
    componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
        // let type = nextProps.match.params.type;
        let keyword =  nextProps.location.state.keyword;
        // let keyword = this.getKeyword(nextProps.location.search);
        // console.log("componentWillReceiveProps=" + keyword);

        this.setState({data: this.getPositionList(keyword)})

        // console.log("搜索");
    }

    render(): React.ReactNode {
        /*数据准备*/
        // let type = this.state.type;
        // let typeName = this.getTypeName(type);
        let dataList = this.state.data;
        let count = `共显示${dataList.length}个职位`;

        return (
            <div id="JobTypeView">
                <div className="JobTypeView-bg-container">
                    <div className={"container JobTypeView-top-container"}>
                        {/*<div className="JobTypeView-top-container">*/}
                        <SearchBox />
                        <JobCategory type={this.state.type} /> {/*将类型传入*/}
                        {/*</div>*/}
                    </div>
                </div>

                {/*岗位容器*/}
                <div style={{background:"rgb(246, 246, 246)"}}>
                    <div className={"container"}>
                        <Row>
                            <Col span={24}>
                                <div className="JobTypeView-position-container">
                                    <div className="JobTypeView-position-container-title">
                                        <span>查询结果</span>
                                        <span>{count}</span>
                                    </div>

                                    {/*岗位列表渲染*/}
                                    <div className="JobTypeView-position-list-container">
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={dataList}
                                            renderItem={item => (
                                                <JobListItem def={item}/>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}