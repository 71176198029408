import "./Scroller.less";
import React from "react";

/**
 * 循环滚动列表
 */
export default class Scroller extends React.Component<any,any> {

    // static propTypes = {
    //     content: PropTypes.node.isRequired,
    //     gap: PropTypes.number,
    //     speed: PropTypes.number
    // }

    static defaultProps = {
        gap: 0,
        speed: 100
    }

    componentDidMount() {

        const wrap:any = this.refs.wrap;
        wrap.style.height = 145 + 'px'; // 初始值

        this.test();
    }

    MyMar:any;
    timerId:any;

    test = () => {
        const {speed} = this.props;
        const wrap:any = this.refs.wrap;
        const scrollDiv1:any = this.refs.content1;
        const scrollDiv2:any = this.refs.content2;
        function Marquee() {
            if (scrollDiv2.offsetHeight === wrap.scrollTop) {
                wrap.scrollTop -= scrollDiv1.offsetHeight;
            }
            wrap.scrollTop++;
        }

        clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
            // console.log(scrollDiv1.offsetHeight);
            // 使用定时器 确保DOM已经加载完毕
            wrap.style.height = scrollDiv1.offsetHeight + 'px';

            clearInterval(this.MyMar);
            this.MyMar = setInterval(Marquee, speed);
        }, 600);

        wrap.onmouseover =  () => {
            clearInterval(this.MyMar);
        };

        wrap.onmouseout =  () => {
            this.MyMar = setInterval(Marquee, speed);
        };
    }

    componentWillUnmount(): void {
        clearTimeout(this.timerId);
        clearInterval(this.MyMar);
        const wrap:any = this.refs.wrap;
        wrap.onmouseover = null;
        wrap.onmouseout = null;
    }

    // componentWillReceiveProps(nextProps:any) {
    //     // if (nextProps.content.props.children && nextProps.content.props.children.length > 0) {
    //     //     this.test();
    //     // }
    //
    //     // if (!this.props.content.props.children && nextProps.content.props.children && nextProps.content.props.children.length > 0) {
    //     //     this.test()
    //     // }
    // }

    render() {
        const { content } = this.props;
        return (
            <div>
                <div ref="wrap" className="cycle-wrap">
                    <div ref="content1" className="cycle-content">
                        {content}
                    </div>
                    <div ref="content2" className="cycle-content">
                        {content}
                    </div>
                </div>
            </div>
        );
    }
}