import React from "react";
import "./NewFooter.less"
import {Col, Row} from "antd";
import FooterUnderline from "./FooterUnderline";
import {Link} from "react-router-dom";
import logo from "../../assets/images/ui/logo.png";

/**
 * 新版页脚兼容PC和移动端
 * @author shawn
 */
export default class NewFooter extends React.Component {

    render(): React.ReactNode {
        return (
            <div id="NewFooter">
                <div className={"container"}>
                    <Row align={"middle"} id={"shawn-test"}>
                        <Col span={24} md={3} >

                            <Row align={"middle"}>
                                <Col span={12} md={24}>
                                    <div className={"item-title"}>加入我们</div>
                                </Col>
                                <Col span={12} md={24}>
                                    <Link to={"/job"}>
                                        <div className={"item-text"}>
                                            人才招聘
                                        </div>
                                    </Link>
                                </Col>
                            </Row>

                        </Col>

                        {/*下划线*/}
                        <Col span={24} md={0} >
                            <FooterUnderline/>
                        </Col>

                        <Col span={24} md={3} >
                            <Row align={"middle"}>
                                <Col span={12} md={24}>
                                    <div className={"item-title"}>商务合作</div>
                                </Col>
                                <Col span={12} md={24}>
                                    <Link to={"/cooperation"}>
                                        <div className={"item-text"}>
                                            商务洽谈
                                        </div>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24} md={0}>
                            <FooterUnderline/>
                        </Col>

                        <Col span={24} md={0}>
                            <FooterUnderline/>
                        </Col>
                        <Col span={24} md={18} id={"shawn-test-2"}>

                            <Row align={"middle"} justify={"end"}>
                                {/*<Col span={24} md={{order:2}}>*/}
                                    {/*<div className='myFooter-right-bottom'>*/}
                                        {/*<div>*/}
                                            {/*沪ICP备16048865号-1*/}
                                        {/*</div>*/}
                                        {/*<div>*/}
                                            {/*All Rights Reserved. 拓娱网络 版权所有*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                {/*</Col>*/}
                                <Col span={24} order={3} md={{order:1, offset:2, span:8}}>

                                    <div id={"NewFooter-copyright"}>
                                        <Row justify={"start"} align={"middle"}>
                                            <Col span={8} offset={4} md={{offset:0, span:14}}>

                                                <div style={{
                                                    textAlign:"right",
                                                    borderRight: "0.04rem solid rgb(179,48,68)",
                                                    paddingRight: "0.1rem",
                                                }}>
                                                    <img src={logo} alt={""} style={{maxWidth: "100%"}}></img>
                                                </div>

                                            </Col>

                                            <Col span={12} md={{span:10}} >
                                                <div style={{
                                                    fontSize: '0.24rem',
                                                    // fontSize: '0.2rem',
                                                    color: "white",
                                                    textAlign: "left",
                                                    paddingLeft: "0.1rem",

                                                }} id={"NewFooter-BaoDianGame"}
                                                >拓娱游戏</div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>

                                <Col span={24} order={2} md={{span:0}}  style={{
                                    textAlign: "center",
                                    background: "rgb(39, 42, 44)",
                                    color: "gray",
                                    fontSize: "0.16rem",
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                    height: "0.2rem",
                                    lineHeight: "0.2rem",
                                    // marginBottom: "0.2rem"
                                }}>
                                    <div>

                                        <Row justify={"center"} align={"middle"} gutter={[8, 8]}>
                                            <Col span={12}>

                                                <div style={{textAlign: "right", paddingRight: "0.04rem"}}>
                                                    上海拓娱网络科技有限公司
                                                </div>

                                            </Col>

                                            <Col span={12}>
                                                <div style={{textAlign: "left"}}>
                                                    <a href="https://beian.miit.gov.cn/" target="_blank"  rel="noopener noreferrer">沪ICP备16048865号-1</a>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                </Col>

                                <Col span={24} order={1} md={{order:3, offset:0, span:12}}>
                                    <div style={{
                                        color: "gray",
                                        fontSize: "0.14rem",
                                        textAlign: "center",
                                        // marginTop: "0.1rem"
                                        paddingTop: "0.1rem",
                                        paddingBottom: "0.1rem"
                                    }} id={"NewFooter-warning"}>
                                        抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。<br/>
                                        适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </div>

                <div className={"container"} style={{
                    textAlign: "center",
                    background: "rgb(39, 42, 44)",
                    color: "gray",
                    fontSize: "0.16rem",
                    paddingTop: "0.1rem",
                    paddingBottom: "0.2rem",
                    height: "0.5rem",
                    lineHeight: "0.5rem"
                }} id={"NewFooter-company"}>
                    上海拓娱网络科技有限公司&ensp;&ensp; <a href="https://beian.miit.gov.cn/" target="_blank"  rel="noopener noreferrer">沪ICP备16048865号-1</a>
                </div>
            </div>
        );
    }
}