import './App.css';
import React from 'react';
import MyHeader from "./components/header/MyHeader";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Jobs from "./components/jobs/Jobs";
import Home from "./components/home/Home";
import Product from "./components/product/Product";
import Contact from "./components/contact/Contact";
import Cooperation from "./components/cooperation/Cooperation";
import ProductDesc from "./components/product/ProductDesc";
import Position from "./components/jobs/Position";
import JobTypeView from "./components/jobs/JobTypeView";
import SearchResult from "./components/jobs/SearchResult";
import ScrollToTop from "./ScrollToTop";
import NewFooter from "./components/footer/NewFooter";
import {loadAllConfigs} from "./config/xls";

/**
 * 网站根组件
 * @author shawn
 */
class App extends React.Component {

    componentWillMount(): void {
        // console.log("进来啦啦啦");
        /*加载所有配表*/
        loadAllConfigs();
        // $(window).resize(this.resetFontSize);
        this.resetFontSize();
        window.addEventListener("resize", this.resetFontSize);
        // $(window).
    }


    componentDidMount(): void {
        // console.error("componentDidMount")
        // this.resetFontSize();
        /*动态修改字体大小*/
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.resetFontSize);
    }

    resetFontSize = () => {
        let deviceWidth = document.documentElement.clientWidth;
        if (deviceWidth > 750) {
            deviceWidth = 750;
        }
        // console.log( document.documentElement);
        // $(document.documentElement).css("fontSize", deviceWidth / 7.5 + 'px');
        document.documentElement.style.fontSize = deviceWidth / 7.5 + 'px';
        // document.body.style.fontSize = "100%";
    };

    render() {
        return (
            <Router>
                <MyHeader/>
                <div id="main-container">
                    <ScrollToTop>
                        <Switch>
                            {/*主页*/}
                            <Route exact path="/" component={Home} />

                            {/*游戏产品*/}
                            <Route exact path="/product" component={Product} />

                            {/*产品详情*/}
                            <Route path="/product/:id" component={ProductDesc} />

                            {/*人才招聘首页*/}
                            <Route exact path="/job"  component={Jobs} />

                            {/*岗位分类*/}
                            <Route path="/job/:type"  component={JobTypeView} />

                            {/*联系我们*/}
                            <Route path="/contact" component={Contact} />

                            {/*岗位描述*/}
                            <Route path="/position/:id" component={Position} />

                            {/*商务合作*/}
                            <Route path="/cooperation" component={Cooperation} />

                            <Route path="/search"  component={SearchResult} />
                        </Switch>

                    </ScrollToTop>
                </div>
                <NewFooter />
            </Router>
        )
    }
}

export default App;


// function RouteWithSubRoutes(route:any) {
//     return (
//         <Route
//             path={route.path}
//             render={props => (
//                 <route.component {...props}  exact={route.exact} routes={route.routes} />
//             )}
//         />
//     );
// }
// /**
//  * 路由配置
//  * @author shawn
//  */
// const routes = [
//     {
//         path: "/",
//         component: Home,
//         exact: true,
//     },
//     {
//         path: "/games",
//         component: Games
//     },
//     {
//         path: "/jobs",
//         component: Jobs
//     }
// ];

