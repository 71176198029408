import {BaseDef} from "./BaseDef";
import {number, splitToArray, string} from "./Converters";

/**
 * 合作联系配置
 * @author shawn
 */
export class PageCooperationDef extends BaseDef({
        id: number(),
        type: string(),
        title: string(),
        desc: string(),
        subDesc: splitToArray("|", string()),
    },
) {

}