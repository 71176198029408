import {Definitions} from "./Definitions";

type Constructor<T, P extends any[] = any[]> = new(...args: P) => T
type SpecifiedTypeKeys<T, P> = { [K in keyof T]: Required<T>[K] extends P ? K : never }[keyof T];
/**
 * Created by deemo on 2018/12/19.
 */
export function createConfig<T>(json: { [key: string]: string }[], DefClass: Constructor<T, [{ [key: string]: string }]>, key: SpecifiedTypeKeys<T, string | number>): Definitions<T> {
    const definitions = new Definitions<T>();
    for (let i = 0, len = json.length; i < len; i++) {
        const def = new DefClass(json[i]);
        definitions.addItem(def[key] as any, def);
    }
    return definitions;
}