import React from "react";
import "./Jobs.css";
import SearchBox from "./SearchBox";
import JobCategory from "./JobCategory";
import {Col, List, Row} from "antd";
import {positionConfig} from "../../config/xls";
import RecommendListItem from "./RecommendListItem";
import Scroller from "../test/Scroller";
import {shuffle} from "../../utils/ArrayUtils";

/**
 * 求职招聘
 * @author shawn
 */
export default class Jobs extends React.Component<any,any> {

    render() {
        let dataList = positionConfig.toArray();

        let list1 = shuffle(dataList.concat());
        let list2 = shuffle(dataList.filter(def => def.recommend > 0));

        return (
            <div id="Jobs">
                {/*求职招聘*/}
                {/*拓娱网络●招聘专区*/}

                <div className="jobs-bg">

                    <div className={"container"}>

                    {/*<Row>*/}
                        {/*<Col span={2}></Col>*/}
                        {/*<Col span={20}>*/}

                            <div className="jobs-bg-container">
                                {/*标题*/}
                                {/*<h2 className="jobs-title">  </h2>*/}
                                <h2 className="jobs-title">拓娱网络 · 招聘专区</h2>

                                {/*搜索框*/}
                                <SearchBox/>

                                {/*职位分类*/}
                                <JobCategory/>
                                {/*标题*/}
                                {/*<h2 className="jobs-title">拓娱网络●招聘专区</h2>*/}
                                {/*标题*/}
                                {/*<h2 className="jobs-title">拓娱网络●招聘专区</h2>*/}
                            </div>

                        {/*</Col>*/}
                        {/*<Col span={2}></Col>*/}
                    {/*</Row>*/}

                    </div>
                    {/*搜索框*/}

                    {/*分类*/}
                    {/*推荐职位*/}
                </div>

                <div style={{background: "rgb(246, 246, 246)"}}>

                <div className={"container"}  style={{background: "white"}}>

                    <div className={"jobs-recommend-title"}>职位推荐</div>
                    <div style={{paddingBottom: "0.3rem"}}>
                        <Row>
                            <Col span={12} md={{offset:1, span:11}}>
                                <div className={"global-title-text"}>最新职位</div>
                                <div style={{height: "2.5rem", overflow: "hidden", marginLeft: "0.12rem"
                                }}
                                >
                                    <Scroller speed={100} content={
                                        <List
                                            itemLayout="vertical"
                                            dataSource={list1}
                                            renderItem={item => (
                                                <RecommendListItem def={item}/>
                                            )}
                                        />
                                    }>
                                    </Scroller>
                                </div>
                            </Col>

                            <Col span={12} md={{offset:1, span:11}}>
                                <div className={"global-title-text"}>热门职位</div>
                                <div style={{height: "2.5rem", overflow: "hidden", marginLeft: "0.12rem"
                                }}
                                >
                                    <Scroller speed={100} content={
                                        <List
                                            itemLayout="vertical"
                                            dataSource={list2}
                                            renderItem={item => (
                                                <RecommendListItem def={item}/>
                                            )}
                                        />
                                    }>
                                    </Scroller>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}


