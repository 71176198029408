import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import "./layout.less";
import "./global.less";


/*路由规则放入到App组件中*/
ReactDOM.render(<App/>, document.getElementById('root'));

/*路由规则放入到App组件中*/
// ReactDOM.render(    <div
//     style={{
//         width: '200px',
//         whiteSpace: 'nowrap',
//     }}
// >
//     <Marquee>
//
//         <span>xxxxxxxxxxxx</span>
//         {/*<div></div>*/}
//         {/*<HomeGameCarousel/>*/}
//         {/*Some really really really really really long text*/}
//         {/*<HomeCarousel/>*/}
//     </Marquee>
// </div>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();