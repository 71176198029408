import React from "react";
import {Col, Row} from 'antd';
import './MyHeader.less'
import {Link, withRouter} from "react-router-dom";
import $ from "jquery";
import logo from "../../assets/images/ui/logo.png";

/**
 * 页眉, 使用栅格化重新制作
 *  xs	<576px 响应式栅格，可为栅格数或一个包含其他属性的对象	number|object	-
    sm	≥576px 响应式栅格，可为栅格数或一个包含其他属性的对象	number|object	-
    md	≥768px 响应式栅格，可为栅格数或一个包含其他属性的对象	number|object	-
    lg	≥992px 响应式栅格，可为栅格数或一个包含其他属性的对象	number|object	-
    xl	≥1200px 响应式栅格，可为栅格数或一个包含其他属性的对象	number|object	-
    xxl	≥1600px 响应式栅格，可为栅格数或一个包含其他属性的对象
 *
 * @author shawn
 */
class MyHeader extends React.Component<any, any> {

    constructor(props:any) {
        super(props);
        this.state = {
            path: "/"
        }
    }

    componentDidMount(): void {
        $(".inner-nav").click(()=> { //点击菜单列表项，收回菜单
            $(".inner-header-icon").click();//触发菜单按钮点击事件
        })

        this.setState({
            path: this.props.location.pathname
        })
    }

    componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
        this.setState({
            path: nextProps.location.pathname
        })
    }

    render() {
        let isHome:boolean = this.state.path === '/';
        let isCooperation:boolean = this.state.path === '/cooperation';
        let isContact:boolean = this.state.path === '/contact';
        let isProduct:boolean = this.state.path.indexOf("/product") !== -1;
        let isJobs: boolean = false;
        if (!isHome && !isContact && !isCooperation && !isProduct) {
            isJobs = true;
        }
        return (
            /*添加id=header得样式*/
            <header id="header">
                <div className="container">
                    <Row >
                        <Col span={6}>
                            {/*添加恭喜Logo*/}
                            {/*<a href="/">*/}
                            <Link to='/'>
                                <div style={{display: "table-cell", height: "60px", verticalAlign: "middle"}}>
                                    <img src={logo} alt="" className='header_logo_img'></img>
                                </div>
                            </Link>
                        </Col>

                        {/*导航栏*/}
                        <Col md={18} span={0}>
                            <nav id="header_nav">
                                <ul>
                                    <li className={`${isHome ? "header_nav_li_width100": ""}`}>
                                        <Link to='/'  className={`${isHome ? "header_nav-li-checked": ""}`}>首页</Link>
                                    </li>
                                    <li  className={`${isProduct ? "header_nav_li_width100": ""}`}>
                                        <Link to='/product' className={`${isProduct ? "header_nav-li-checked": ""}`}>游戏产品</Link></li>
                                    <li className={`${isJobs ? "header_nav_li_width100": ""}`}>
                                        <Link to='/job'  className={`${isJobs ? "header_nav-li-checked": ""}`}
                                              rel="noopener noreferrer"
                                        >人才招聘</Link></li>
                                    <li className={`${isCooperation ? "header_nav_li_width100": ""}`}>
                                        <Link to='/cooperation' className={`${isCooperation ? "header_nav-li-checked": ""}`}>商务合作</Link></li>
                                    <li className={`${isContact ? "header_nav_li_width100": ""}`}>
                                        <Link to='/contact'  className={`${isContact ? "header_nav-li-checked": ""}`}>联系我们</Link></li>
                                </ul>
                            </nav>
                        </Col>

                        {/*手机端显示*/}
                        <Col md={0} span={18}>
                            {/*移动端菜单栏*/}
                            {/*<div id='header_mobile_menus' onClick={this.onMenuClick}>*/}
                            {/*<MenuOutlined />*/}
                            {/*</div>*/}
                            <div id='header_mobile_menus' className="inner-header-icon inner-header-icon-out" onClick={this.onMenuClick}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Col>
                    </Row>

                    {/*手机端隐藏导航*/}
                    <Row className="inner-nav-row">
                        <Col md={0} span={24}>
                            <div className="inner-nav">
                                <Link to='/'>拓娱网络</Link>
                                <Link to='/product'>游戏产品</Link>
                                <Link to='/job'>人才招聘</Link>
                                <Link to='/cooperation'>商务合作</Link>
                                <Link to='/contact'>联系我们</Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </header>
        )
    }

    onMenuClick() {
        $('#header_mobile_menus').toggleClass("inner-header-icon-click inner-header-icon-out");
        $(".inner-nav").slideToggle(250);
    }
}
export default withRouter(MyHeader);