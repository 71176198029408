

import 'rc-banner-anim/assets/index.css';
import "./HomeCarousel.less";

import React from "react";
import BannerAnim from 'rc-banner-anim';

const { Element } = BannerAnim;
const BgElement = Element.BgElement;
/**
 * 首页轮播图
 * @author shawn
 * 使用rc-banner-anim组件
 * 参考: https://motion.ant.design/components/banner-anim-cn#components-banner-anim-demo-simple
 *       http://react-component.github.io/banner-anim/
 */
export default class HomeCarousel extends React.Component<any, any> {

    imgArray:string[] = [];

    constructor(props:any) {
        super(props);
        this.imgArray = [
            // 'https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg',
            // 'https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg',
            // require('../../assets/images/home/top_banner/top_banner_1.png'),
            require('../../assets/images/home/top_banner/top_banner_2.png'),
            // require('../../assets/images/home/top_banner/u247.png'),
            // require('../../assets/images/home/top_banner/u276.jpg'),
            // require('../../assets/images/home/top_banner/u2021.jpg'),
        ];
        this.state = {
            intShow: 0,
            prevEnter: false,
            nextEnter: false,
            thumbEnter: false,
        };
    }

    onChange = (type:any, int:any) => {
        if (type === 'before') {
            this.setState({
                intShow: int,
            });
        }
    }

    getNextPrevNumber = () => {
        let nextInt = this.state.intShow + 1;
        let prevInt = this.state.intShow - 1;
        if (nextInt >= this.imgArray.length) {
            nextInt = 0;
        }
        if (prevInt < 0) {
            prevInt = this.imgArray.length - 1;
        }

        return [prevInt, nextInt];
    }

    prevEnter = () =>  {
        this.setState({
            prevEnter: true,
        });
    }

    prevLeave = () => {
        this.setState({
            prevEnter: false,
        });
    }

    nextEnter = () => {
        this.setState({
            nextEnter: true,
        });
    }

    nextLeave = () =>  {
        this.setState({
            nextEnter: false,
        });
    }

    onMouseEnter = () =>  {
        this.setState({
            thumbEnter: true,
        });
    }

    onMouseLeave = () =>  {
        this.setState({
            thumbEnter: false,
        });
    }

    render(): React.ReactNode {
        // const intArray = this.getNextPrevNumber();
        // const thumbChildren = this.imgArray.map((img, i) =>
        //     <span key={i}><i style={{ backgroundImage: `url(${img})` }} /></span>
        // );

        let elementList = [];
        for (let i = 0, len = this.imgArray.length; i < len; i++) {
            elementList.push(
                <Element key={`${i+1}`}
                         prefixCls="banner-user-elem"
                >
                    <BgElement
                        key="bg"
                        className="bg"
                        style={{
                            backgroundColor: "#64CBCC",
                            backgroundImage: `url(${this.imgArray[i]})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            // backgroundRepeat: "no-repeat"
                        }}
                    />
                    {/*<TweenOne className="banner-user-title" animation={{ y: 30, opacity: 0, type: 'from' }}>*/}
                    {/*Ant Motion Banner*/}
                    {/*</TweenOne>*/}
                    {/*<TweenOne*/}
                    {/*className="banner-user-text"*/}
                    {/*animation={{ y: 30, opacity: 0, type: 'from', delay: 100 }}*/}
                    {/*>*/}
                    {/*The Fast Way Use Animation In React*/}
                    {/*</TweenOne>*/}
                </Element>
            );
        }

        // 动画切换效果
        //'across' | 'vertical' | 'acrossOverlay' | 'verticalOverlay' | 'gridBar' | 'grid';

        return (
            <div id={"HomeCarousel"}>
                <BannerAnim
                    autoPlay={true}
                    type={['across', 'vertical', 'acrossOverlay', 'verticalOverlay']}
                    // autoPlaySpeed={3000}
                    // onChange={this.onChange}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    prefixCls="custom-arrow-thumb"
                    // sync={true}
                    // aria-busy={true}
                >
                    {elementList}
                    {/*<Arrow arrowType="prev" key="prev" prefixCls="user-arrow" component={TweenOne}*/}
                    {/*onMouseEnter={this.prevEnter}*/}
                    {/*onMouseLeave={this.prevLeave}*/}
                    {/*componentProps={{animation: { left: this.state.prevEnter ? 0 : -120 }}}*/}

                    {/*>*/}
                    {/*<div className="arrow"></div>*/}
                    {/*<TweenOneGroup*/}
                    {/*enter={{ opacity: 0, type: 'from' }}*/}
                    {/*leave={{ opacity: 0 }}*/}
                    {/*appear={false}*/}
                    {/*className="img-wrapper" component="ul"*/}
                    {/*>*/}
                    {/*<li style={{ backgroundImage: `url(${this.imgArray[intArray[0]]})`}} key={intArray[0]} />*/}
                    {/*</TweenOneGroup>*/}
                    {/*</Arrow>*/}
                    {/*<Arrow arrowType="next" key="next" prefixCls="user-arrow" component={TweenOne}*/}
                    {/*onMouseEnter={this.nextEnter}*/}
                    {/*onMouseLeave={this.nextLeave}*/}
                    {/*componentProps={{animation: { right: this.state.nextEnter ? 0 : -120 }}}*/}
                    {/*>*/}
                    {/*<div className="arrow"></div>*/}
                    {/*<TweenOneGroup*/}
                    {/*enter={{ opacity: 0, type: 'from' }}*/}
                    {/*leave={{ opacity: 0 }}*/}
                    {/*appear={false}*/}
                    {/*className="img-wrapper"*/}
                    {/*component="ul"*/}
                    {/*>*/}
                    {/*<li style={{ backgroundImage: `url(${this.imgArray[intArray[1]]})`}} key={intArray[1]} />*/}
                    {/*</TweenOneGroup>*/}
                    {/*</Arrow>*/}
                    {/*<Thumb prefixCls="user-thumb" key="thumb" component={TweenOne}*/}
                           {/*componentProps={{animation: { bottom: this.state.thumbEnter ? 0 : -70 }}}*/}
                    {/*>*/}
                        {/*{thumbChildren}*/}
                    {/*</Thumb>*/}
                </BannerAnim>
            </div>
        );
    }
}