import React from "react";
import "./FooterUnderline.less"


/**
 * 页脚下划线组件
 * @author shawn
 */
export default class FooterUnderline extends React.Component {

    render(): React.ReactNode {
        return (
            <div id={"FooterUnderline"}>

            </div>
        );
    }
}