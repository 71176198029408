import React from "react";
import "./JobListItem.css";
import {Link} from "react-router-dom";
import {PositionDef} from "../../config/PositionDef";
import {RightCircleOutlined} from "@ant-design/icons/lib";
import TimeUtils from "../../utils/TimeUtils";

/**
 * 单个职位列表项说明
 * @author shawn
 */
export default class JobListItem extends React.Component<any, any> {

    constructor(props:any) {
        super(props);

        this.state = {
            def: this.props.def
        };
        // console.log("得到岗位定义了");
        // console.log(this.state)
    }

    componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
        // console.log("==========变化把");
        // console.log(nextProps)
        this.setState({
            def: nextProps.def
        })
    }

    render(): React.ReactNode {

        let def:PositionDef = this.state.def;

        // let date = "2020年3月27日更新"
        // var day1 = new Date();
        // 3  day1.setDate(day1.getDate() - 1);
        // 4  var s1 = day1.format("yyyy-MM-dd");
        // 5  //前天的时间
        // 6  var day2 = new Date();
        // 7  day2.setDate(day2.getDate() - 2);
        // 8  var s2 = day2.format("yyyy-MM-dd");
        // let date = new Date();
        // 获取当天日期
        // let nowDate = new Date();
        // let year = nowDate.getFullYear();
        // let month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
        // let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
        let dateStr = TimeUtils.getTodayYYYYMMDD() + "更新";
        // let dateStr = date.setDate(date.getDate()).format("yyyy年MM月dd日");

        let info = `${def.tips} | ${def.position} | ${def.degree} | ${def.workLimit} | ${dateStr}`;
        let duty:string = def.duty;
        duty = duty.replace("/\\n/g", "  ");

        return (
            <Link to={`/position/${this.state.def.id}`}>
                <div className="JobListItem">

                    {/*标题*/}
                    <div className="JobListItem-title">
                        {def.title}
                    </div>

                    {/*基础信息*/}
                    <div className="JobListItem-info-text">
                        {info}
                    </div>

                    {/*职责*/}
                    <div className="JobListItem-info-duty">
                        {duty}
                    </div>

                    {/*向右方向的箭头*/}
                    <RightCircleOutlined className="JobListItem-info-arrow" />
                </div>
            </Link>
        );
    }
}