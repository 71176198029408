import data from "./configs.json";
import {Definitions} from "./Definitions";
import {PositionDef} from "./PositionDef";
import {createConfig} from "./ConfigUtils";
import {GameShowDef} from "./GameShowDef";
import {PageCooperationDef} from "./PageCooperationDef";
import {PageFirstDef} from "./PageFirstDef";

export let positionConfig:Definitions<PositionDef>;
export let gameShowConfig: Definitions<GameShowDef>;   //游戏产品页展示用
export let pageCooperationConfig: Definitions<PageCooperationDef>; //合作联系页
export let pageFirstConfig: Definitions<PageFirstDef>;

export function loadAllConfigs() {
    // 加载时不需要带后缀名
    // return loadByUrl("config/configs").then((data: { [xmlName: string]: any }) => {

        // if (CC_DEBUG) cc.log("配表:", data)
        // let jsonData = data;
        positionConfig = createConfig(data.position, PositionDef, "id");
        gameShowConfig = createConfig(data.gameShow, GameShowDef, "id");
        pageCooperationConfig = createConfig(data.pageCooperation, PageCooperationDef, "id");
        pageFirstConfig = createConfig(data.pageFirst, PageFirstDef, "id");
        // itemConfig = createConfig(jsonData.item, ItemDef, "id");
        // achievementConfig = createConfig(jsonData.achievement, AchievementDef, "id");
        // slotConfig = createConfig(jsonData.slot, SlotDef, "id");
        // lotteryConfig = createConfig(jsonData.lottery, LotteryDef, "id");
        // rewardConfig = createConfig(jsonData.reward, RewardDef, "id");
        // shopConfig = createConfig(jsonData.shop, ShopDef, "id");
        // signConfig = createConfig(jsonData.daySign, SignDef, "id");
        // freeWeapon = createConfig(jsonData.freeWeapon, FreeWeaponDef, "id");
        // invite = createConfig(jsonData.invite, InviteDef, "id");
        // globalSet = new GlobalSet(jsonData.globalSet);
        // weaponWelfareConfig = createConfig(jsonData.weaponWelfare, WeaponWelfareDef, "id");
        // groupShareConfig = createConfig(jsonData.groupShare, GroupShareDef, "id");
        // superWeaponList = itemConfig.filter(item => item.lockType === 'slotCard');

        // 加载配表完毕后释放
        // cc.loader.releaseRes("config/configs");
    // });
}
