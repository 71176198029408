/** 原地打乱数组 */
export function shuffle<T>(list: T[]): T[] {
    let index: number
    let t: T
    let i = list.length
    while (i > 1) {
        index = Math.floor(Math.random() * i--)
        // [list[index], list[i]] = [list[i], list[index]];
        t = list[i]
        list[i] = list[index]
        list[index] = t
    }
    return list
}