
import {BaseDef} from "./BaseDef";
import {number, splitToArray, string} from "./Converters";

/**
 * 游戏产品展示 九宫格
 * @author shawn
 */
export class GameShowDef extends BaseDef({
        id: number(),
        sort: number(),
        name: string(),
        desc: string(),
        res: string(),
        tips: splitToArray("|", string()),  //微信
        detailDesc: splitToArray("|", string()), //描述
    },
) {

}